<template>
<html lang="ja" prefix="og: http://ogp.me/ns#">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>藤沢の貸し切り民泊・エアビー・Airbnb | 鎌倉・湘南・江の島</title>
    <meta name="description" content="藤沢の民泊をお探しの方におすすめの貸し切り戸建て住宅です。Airbnb・楽天vacation styayからも貸し出しを行っています。藤沢駅まで徒歩6分の好立地にあり、多くの方にご利用いただいています。">
    <meta name="keywords" content="民泊,エアビー,Airbnb,ゲストハウス,guesthouse,hostel,藤沢,鎌倉,湘南,江ノ島,平塚">
    <meta property="og:image" content="../assets/OGP.jpg">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600&display=swap" rel="stylesheet">
</head>

<body>

    <header>
        <ul>
            <li><router-link to="/">HOME</router-link></li>
            <li><router-link to="/room">お部屋について</router-link></li>
            <li><router-link to="/reserve">ご予約</router-link></li>
            <li><router-link to="/access">アクセス</router-link></li>
            <li><router-link to="/tour">周辺観光</router-link></li>
            <li class="contactButton"><router-link to="/contact" class="contact"><img class="contactImg" src="../assets/contact.png" alt="">お問い合わせはこちら</router-link></li>
        </ul>
    </header>

    <form name="form">
        <select class="lang" name="select" onchange="if(document.form.select.value){location.href=document.form.select.value;}">
            <option value="https://fujisawa-minshuku.com">Japanese（日本語）</option>
            <option value="https://fujisawa-minshuku.com/#/E">English</option>
            <option value="https://fujisawa-minshuku.com/#/C">Chinese（中文）</option>
        </select>
    </form>

    <div class="hamburger-menu pcOFF">
        <input type="checkbox" id="menu-btn-check">
        <label for="menu-btn-check" class="menu-btn"><span></span></label>
        <div class="menu-content">
            <ul>
                <li><router-link to="/">HOME</router-link></li>
                <li><router-link to="/room">お部屋について</router-link></li>
                <li><router-link to="/reserve">ご予約</router-link></li>
                <li><router-link to="/access">アクセス</router-link></li>
                <li><router-link to="/tour">周辺観光</router-link></li>
                <li><router-link to="/contact">お問い合わせはこちら</router-link></li>
            </ul>
        </div>
    </div>

    <main>
        <section id="top">
            <img src="../assets/top.png" alt="">
            <div class="titleBox">
                <h2><span class="text-combine ldk">4</span><span class="text-combine ldk">L</span><span class="text-combine ldk">D</span><span class="text-combine ldk">K</span>貸切戸建て住宅</h2>
                <h2>観光の拠点に最適</h2>
                <span></span>
                <p>鎌倉・江ノ島まで<span class="text-combine">30</span>分以内</p>
                <p>藤沢駅から徒歩<span class="text-combine">6</span>分</p> 
            </div>
        </section>

        <div class="first">
            <h1>藤沢の貸し切り民宿</h1>
            <p>藤沢の民泊をお探しの方におすすめの貸し切り戸建て住宅です。<br>
                Airbnb・楽天Vacation STAYからも貸し出しを行っています。<br>
                藤沢駅まで徒歩6分の好立地にあり、多くの方にご利用いただいています。</p>
        </div>

        <section id="introduction">
            <div class="introduction intro_1">
                <div>
                    <h3>4LDK貸し切り戸建住宅</h3>
                    <img class="pcOFF" src="../assets/intro_1.png" alt="">
                    <p>観光の拠点に最適。その他小規模パーティー、誕生日会、研修会、合宿、用途多様。各種相談承ります。
                        貸し切り戸建住宅ですので、設備・備品・敷地内駐車場はすべて無料で使用できます。<br>炊事機材・食器等・洗濯機・ＴＶ・ネット環境・調味料などの生活必需品完備しておりますので、ファミリー・サークル・研修・受験など長期滞在も可能です。<br>
                        徒歩1分圏内にコンビニもございます。南側引地川の角地ですので、日当たり、風通り共に良好です。<br>
                        建物は築７年で丁寧に使用しておりますので、室内・設備共に清潔です。
                    </p>
                    <router-link to="/room">お部屋の情報を見る</router-link>
                </div>
                <img class="spOFF" src="../assets/intro_1.png" alt="">
            </div>

            <div class="introduction intro_2">
                <img class="spOFF" src="../assets/intro_2.png" alt="">
                <div>
                    <h3>大人数でも利用可能</h3>
                    <img class="pcOFF" src="../assets/intro_2.png" alt="">
                    <p>敷布団シーツ、かけ布団カバー、枕カバーは、お客様ごとに交換及び抗菌消毒スプレーしております。<br>
                        寝具は合計８セット分のご用意ができます。
                        ６名様以上での宿泊の場合、事前にご連絡いただければ、寝具の準備を行います。お気軽にご相談ください。<br>
                        <br>
                        寝室1（6.0帖）ダブルベット1台（予備敷布団セット1敷）<br>
                        寝室2（6.7帖）セミダブルベット2台<br>
                        寝室3（7.7帖）セミダブルベット2台<br>
                        和室（5.0帖） 予備敷布団セット2敷
                        </p>
                </div>
            </div>

            <div class="introduction intro_3">
                <div>
                    <h3>アメニティも豊富</h3>
                    <img class="pcOFF" src="../assets/intro_3.png" alt="">
                    <p>・Wi-Fi完備<br>
                        ・テレビ(YouTube・Amazon Prime Video・Disney+ 見放題)<br>
                        ・歯ブラシ、ドライヤー、シャンプー＆コンディショナー、石鹸、ハンドタオル、バスタオル、調味料、洗剤もご用意しております
                        </p>
                </div>
                <img class="spOFF" src="../assets/intro_3.png" alt="">
            </div>

            <div class="introduction intro_4">
                <img class="spOFF" src="../assets/intro_4.png" alt="">
                <div>
                    <h3>利便性にも優れ、観光にも最適</h3>
                    <img class="pcOFF" src="../assets/intro_4.png" alt="">
                    <p>JR東海道線、小田急江ノ島線、江ノ島電鉄「藤沢駅」より平坦徒歩約6分。<br>
                        無料駐車場1台、近隣は24時間800円Pがございます。<br>
                        スーパーやコンビニも近く、徒歩でサミットまで5分、セブンまで1分、ファミマ・ローソンまで3分となっています。(Airbnb参照)<br>
                        <br>
                        車移動での目安時間は、江ノ島15分・鎌倉20分・逗子葉山30分。<br>
                        横浜中華街、みなとみらい40分・箱根45分。御殿場、富士山周辺60分。<br>
                        観光の拠点として最適です。
                        </p>
                </div>
            </div>
        </section>

        <div class="access">
            <h3>アクセス</h3>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3254.4813418235526!2d139.4861206152496!3d35.34369898027438!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60184fcd41421ead%3A0x3ad0b8c2b22c132e!2z44CSMjUxLTAwMDIg56We5aWI5bed55yM6Jek5rKi5biC5aSn6Yu477yS5LiB55uu77yU4oiS77yS77yR!5e0!3m2!1sja!2sjp!4v1660459563873!5m2!1sja!2sjp" width="600" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <p>
                    神奈川県藤沢市大鋸2-4-21<br>
                    <br>
                    ＪＲ東海道線・小田急江ノ島線・江ノ島電鉄<br>
                    「藤沢駅」より平坦徒歩約6分<br>
                    湘南バイパス入口まで車で10分<br>
                    普通自動車無料駐車スペース1台あり<br>
                    近隣24時間800円有料駐車場多数あり<br>
                    スーパーまで徒歩5分<br>
                    コンビニまで徒歩1分
                </p>
            </div>
        </div>

        <div class="reservation">
            <h2>ご予約方法</h2>
            <p>5つの民宿をご用意しております。<br>
                各民宿はAirbnbからご予約いただけます。<br>
                質問がございましたらお気軽にお問い合わせください。<br>
                中国語対応もしております。</p>
            <div class="reserveButton">
                <a href="https://www.airbnb.jp/rooms/53868433?check_in=2024-03-14&check_out=2024-03-15&guests=1&adults=2&viralityEntryPoint=1&s=76&unique_share_id=0e2aadf1-8553-4e3d-99cd-13311f10fed0">藤沢駅の民宿①のご予約</a>
                <a href="https://www.airbnb.jp/rooms/850904381365141385?check_in=2024-06-01&check_out=2024-06-02&guests=1&adults=1&viralityEntryPoint=1&s=76&unique_share_id=8a60e203-3">藤沢駅の民宿②のご予約</a>
                <a href="https://www.airbnb.jp/rooms/1047485073415257775?check_in=2024-06-01&check_out=2024-06-02&guests=1&adults=1&viralityEntryPoint=1&s=76&unique_share_id=2c4980ee-fa11-499e-8d3a-e6f12cc29b3d">藤沢駅の民宿③のご予約</a>
                <a href="https://www.airbnb.jp/rooms/935201088121284742?check_in=2024-04-07&check_out=2024-04-12&guests=1&adults=1&viralityEntryPoint=1&s=76&unique_share_id=4d089d29-156b-4cb8-aa56-94754737d2b0">鎌倉大船の民宿のご予約</a>
                <a href="https://www.airbnb.jp/rooms/1072896787553928658?check_in=2024-06-01&check_out=2024-06-02&guests=1&adults=1&viralityEntryPoint=1&s=76&unique_share_id=a3eaac3d-6bc2-4d7e-b19b-15c4fb4ced46">湘南片瀬の民宿のご予約</a>
            </div> 
        </div>
 
    </main>
 
    <footer>
        <ul>
            <li><router-link to="/">HOME</router-link></li>
            <li><router-link to="/room">お部屋について</router-link></li>
            <li><router-link to="/reserve">ご予約</router-link></li>
            <li><router-link to="/access">アクセス</router-link></li>
            <li><router-link to="/tour">周辺観光</router-link></li>
            <li><router-link to="/contact">お問い合わせ</router-link></li>
        </ul>
    </footer>

</body>
</html>

</template>

<script>
export default {
    mounted(){
        this.scrollToTop()
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0
            })
        }
    }
}
</script>

<style>
*{
    width: 100%;
    margin: 0;
    box-sizing: border-box;
}

body{
    background-color: #F7F6F0;
    color: #383F45;
    font-family: 'Noto Serif JP', serif;
}

/* ヘッダー */

header{
    position: absolute;
}

header ul{
    display: flex;
}

header ul li{
    display: inline-block;
    width: fit-content;
    margin: 80px 30px;
}


header ul li a{
    text-decoration: none;
    color: #383F45;
    padding: 20px 0;
}


header ul .contact{
    background-color: #829FB7;
    color: #F7F6F0;
    display: flex;
    padding: 15px;
    border-radius: 10px;
}

header ul .contact img{
    width: 25px;
    margin-right: 20px;
}

header ul .contactButton{
    margin: 65px 30px;
}

.lang{
    width: 150px;
    position: absolute;
    top: 7%;
    right: 3%;
}

/* トップ */

#top{
    display: flex;
    justify-content: end;
}

#top img{
    width: 75%;
    position: absolute;
    left: 0;
    top: 20vh;
}


/* トップタイトル */

.titleBox{
    width: 38%;
    height: 100vh;
    background-color: #829FB7;
    display: flex;
    justify-content: end;
    color: #F7F6F0;
    padding: 100px 50px;
}

.titleBox p{
    /* font-size: 20px; */
    font-size: 125%;
    padding: 10px 5px;
}

.titleBox span{
    width: 20px;
}

.titleBox h2{
    /* font-size: 40px; */
    font-size: 250%;
    letter-spacing: 0.3em;
    padding: 10px 8px;
}

.titleBox p, .titleBox h2{
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    width: auto;
}

.text-combine {
    -webkit-text-combine: horizontal;
    -ms-text-combine-horizontal: all;
    text-combine-upright: all;
  }

.ldk{
    padding-bottom: 15px;
}

/* 導入文 */

.first{
    padding: 250px 0 150px 0;
    text-align: center;
    line-height: 3em;
    letter-spacing: 0.1em;
}

.first h1{
    padding-bottom: 20px;
    font-size: 25px;
}

/* ご予約方法 */

.reservation{
    text-align: center;
    line-height: 3em;
    letter-spacing: 0.1em;
    background-color: #EFEDE4;
    padding: 100px;
    margin-bottom: 200px;
}

.reservation h2{
    padding-bottom: 30px;
}

.reservation .reserveButton{
    display: flex;
    justify-content: center;
}

.reservation .reserveButton a{
    text-decoration: none;
    background-color: #E3C149;
    border-radius: 15px;
    color: #fff;
    display: inline-block;
    width: 300px;
    padding: 15px;
    margin: 40px;
}


/* 紹介項目４つ */

.introduction{
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    margin: 100px 0;
    font-weight: 200;
}

.introduction div{
    display: block;
    width:50%;
    padding-left: 15%;
    line-height: 1.8em;
}

.intro_2 div, .intro_4 div{
    padding-right: 15%;
    padding-left: 0%;
}

.introduction h3{
    padding: 60px 0 40px 0;
    font-size: 25px;
    font-weight: 200;
}

.introduction img{
    width: 40%;
}

.intro_1{
    background-color: #6A7E91;
}
.intro_2{
    background-color: #85A8A6;
}
.intro_3{
    background-color: #AA9C90;
}
.intro_4{
    background-color: #C3B57E;
}

/* intro_1部屋情報ボタン */

.intro_1 a{
    text-decoration: none;
    color: #fff;
    background-color: #8D9BA8;
    padding: 10px 50px;
    border-radius: 10px;
    margin-top: 20px;
    display: inline-block;
    width: fit-content;
}

/* アクセス */

.access h3{
    text-align: center;
    font-size: 30px;
}

.access{
    background-color: #E8EBE7;
    padding: 100px 0 50px 0;
    margin: 200px  0;
}

.access div{
    display: flex;
    align-items: center;
    width: 70%;
    margin: 80px auto 100px;
}

.access div p{
    width: fit-content;
    margin-left: 100px;
    line-height: 2em;
}

.access div iframe{
    width: 600px;
}

/* フッター */

footer{
    background-color: #4A5967;
}

footer ul{
    display: flex;
    justify-content: center;
}

footer ul li{
    display: inline-block;
    width: fit-content;
    padding: 80px 30px;
    color: #F7F6F0;
}

footer ul li a{
    text-decoration: none;
    color: #F7F6F0;
}



.pcOFF{
    display: none;
}



@media screen and (max-width:1600px) {



    .titleBox{
        width: 35%;
    }


/* 紹介項目４つ */

.introduction{
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    margin: 100px 0;
    font-weight: 200;
}

.introduction div{
    display: block;
    width:50%;
    padding-left: 15%;
    padding-right: 2%;
    line-height: 1.8em;
}

.intro_2 div, .intro_4 div{
    padding-right: 15%;
    padding-left: 2%;
}

.introduction h3{
    padding: 60px 0 40px 0;
    font-size: 25px;
    font-weight: 200;
}

.introduction img{
    width: 40%;
    min-width: 640px;
}


}



@media screen and (max-width:1480px) {

    .titleBox p{
    /* font-size: 20px; */
    font-size: 150%;
    padding: 10px 5px;
}

.titleBox span{
    width: 20px;
}

.titleBox h2{
    /* font-size: 40px; */
    font-size: 200%;
    letter-spacing: 0.3em;
    padding: 10px 8px;
    height: max-content;
}

    .spOFF{
        display: none;
    }

    .pcOFF{
        display:block;
    }


    /* スマホヘッダー */

    header{
        display: none;
    }
    
    .titleBox{
        width: 70%;
    }

    #top img{
        width: 70%;
        position: absolute;
        left: 0;
        top: 20vh;
    }
    



    /* スマホヘッダー */

    header{
        display: none;
    }

      /* ハンバーガー */

      .menu-btn {
        position: fixed;
        top: 10px;
        left: 10px;
        display: flex;
        height: 60px;
        width: 60px;
        justify-content: center;
        align-items: center;
        z-index: 90;
        background-color: #829FB7;
    }

    .menu-btn span,
    .menu-btn span:before,
    .menu-btn span:after {
        content: '';
        display: block;
        height: 3px;
        width: 25px;
        border-radius: 3px;
        background-color: #ffffff;
        position: absolute;
    }

    .menu-btn span:before {
        bottom: 8px;
    }
    .menu-btn span:after {
        top: 8px;
    }

    #menu-btn-check:checked ~ .menu-btn span {
        background-color: rgba(255, 255, 255, 0);/*メニューオープン時は真ん中の線を透明にする*/
    }

    #menu-btn-check:checked ~ .menu-btn span::before {
        bottom: 0;
        transform: rotate(45deg);
    }

    #menu-btn-check:checked ~ .menu-btn span::after {
        top: 0;
        transform: rotate(-45deg);
    }

    #menu-btn-check {
        display: none;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 80;
        background-color:  #829FB7;
        font-family: 'Noto Serif JP', serif;
    }

    .menu-content ul {
        padding: 70px 30px 0;
    }

    .menu-content ul li {
        border-bottom: solid 1px #F7F6F0;
        list-style: none;
        padding: 30px 0;
    }

    .menu-content ul li a {
        display: block;
        width: 100%;
        font-size: 15px;
        box-sizing: border-box;
        color:#F7F6F0;
        text-decoration: none;
        padding: 9px 15px 10px 0;
        position: relative;
    }

    .menu-content ul li a::before {
        content: "";
        width: 7px;
        height: 7px;
        border-top: solid 2px #F7F6F0;
        border-right: solid 2px #F7F6F0;;
        transform: rotate(45deg);
        position: absolute;
        right: 11px;
        top: 16px;
    }

    .menu-content {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 100%;/*leftの値を変更してメニューを画面外へ*/
        z-index: 80;
        background-color: #829FB7;
        transition: all 0.5s;/*アニメーション設定*/
    }

    #menu-btn-check:checked ~ .menu-content {
        right: 0;/*メニューを画面内へ*/
    }



    /* スマホ紹介項目４つ */

    .introduction{
        display: block;
        margin: 100px 0;
        font-weight: 200;
    }

    .introduction div{
        display: block;
        margin: auto;
        width: 100%;
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 60px;
    }

    .intro_2 div, .intro_4 div{
        padding-right: 0%;
        padding-left: 0%;
    }

    .introduction h3{
        padding: 60px 0 40px 0;
        font-size: 18px;
        text-align: center;
    }

    .introduction p{
        font-size: 15px;
        width: 80%;
        margin:  50px auto 30px;
    }

    .introduction img{
        width: 100%;
        min-width: auto;
    }

    /* スマホintro_1部屋情報ボタン */
    .intro_1 a{
        padding: 10px 50px;
        border-radius: 10px;
        margin: auto;
        display: block;
        width: 80%;
        text-align: center;
    }


    .access div iframe{
        width: 100%;
        height: 550px;
    }



    


}

@media screen and (max-width:1000px) {


    .lang{
        width: 150px;
        position: absolute;
        top: 1%;
        right: 5%;
    }

 /* スマホトップタイトル */

 .titleBox{
    width: 70%;
    height: 60vh;
    padding: 40px 40px;
}

.titleBox p{
    font-size: 90%;
    padding: 0 5px;
}

.titleBox span{
    width: 8px;
}

.titleBox h2{
    font-size: 120%;
    letter-spacing: 0.3em;
    padding: 0 4px;
}

.ldk{
    padding-bottom: 7px;
}
    
}

@media screen and (max-width:800px) {


    #top img{
        width: 100%;
        top: 45vh;
    }

    .titleBox{
        width: 70%;
        height: 60vh;
        padding: 40px 40px;
    }
    
    .titleBox p{
        font-size: 80%;
        padding: 0 5px;
    }
    
    .titleBox span{
        width: 8px;
    }
    
    .titleBox h2{
        font-size: 110%;
        letter-spacing: 0.3em;
        padding: 0 4px;
        height: max-content;
    }

    .ldk{
        padding-bottom: 7px;
    }

    .first{
        margin-top: 30vw;
    }
    

    /* スマホ紹介項目４つ */

    .introduction{
        display: block;
        margin: 100px 0;
        font-weight: 200;
    }

    .introduction div{
        display: block;
        margin: auto;
        width: 100%;
        padding-left: 0%;
        padding-bottom: 60px;
    }

    .intro_2 div, .intro_4 div{
        padding-right: 0%;
        padding-left: 0%;
    }

    .introduction h3{
        padding: 60px 0 40px 0;
        font-size: 18px;
        text-align: center;
    }

    .introduction p{
        font-size: 15px;
        width: 80%;
        margin:  50px auto 30px;
    }

    .introduction img{
        width: 100%;
    }

    /* スマホintro_1部屋情報ボタン */
    .intro_1 a{
        padding: 10px 50px;
        border-radius: 10px;
        margin: auto;
        display: block;
        width: 80%;
        text-align: center;
    }

}

@media screen and (max-width:480px) {

    .spOFF{
        display: none;
    }

    .pcOFF{
        display:block;
    }


    /* スマホヘッダー */

    header{
        display: none;
    }

    #top img{
        width: 100%;
        top: 45vh;
    }

    .lang{
        width: 150px;
        position: absolute;
        top: 1%;
        right: 5%;
    }

    /* スマホトップタイトル */

    .titleBox{
        width: 70%;
        height: 60vh;
        padding: 40px 40px;
    }

    .titleBox p{
        font-size: 30%;
        padding: 0 5px;
    }

    .titleBox span{
        width: 8px;
    }

    .titleBox h2{
        font-size: 80%;
        letter-spacing: 0.3em;
        padding: 0 4px;
    }

    .ldk{
        padding-bottom: 7px;
    }

    /* スマホ導入文 */

    .first{
        padding: 200px 0 80px 0;
        width: 80%;
        margin: auto;
    }

    .first h1{
        padding-bottom: 20px;
        font-size: 18px;
    }

    .first p{
        font-size: 15px;
    }

    /* スマホご予約方法 */

    .reservation{
        padding: 80px 10%;
        margin-bottom: 100px;
    }
    .reservation h2{
        padding-bottom: 30px;
        font-size: 18px;
    }

    .reservation p{
        font-size: 15px;
        margin-bottom: 30px;
    }

    .reservation .reserveButton{
        display: block;
        margin: auto;
    }

    .reservation .reserveButton a{
        text-decoration: none;
        width: 100%;
        padding: 10px;
        margin: 20px 0;
    }

    /* スマホ紹介項目４つ */

    .introduction{
        display: block;
        margin: 100px 0;
        font-weight: 200;
    }

    .introduction div{
        display: block;
        margin: auto;
        width: 100%;
        padding-left: 0%;
        padding-bottom: 60px;
    }

    .intro_2 div, .intro_4 div{
        padding-right: 0%;
        padding-left: 0%;
    }

    .introduction h3{
        padding: 60px 0 40px 0;
        font-size: 18px;
        text-align: center;
    }

    .introduction p{
        font-size: 15px;
        width: 80%;
        margin:  50px auto 30px;
    }

    .introduction img{
        width: 100%;
    }

    /* スマホintro_1部屋情報ボタン */
    .intro_1 a{
        padding: 10px 50px;
        border-radius: 10px;
        margin: auto;
        display: block;
        width: 80%;
        text-align: center;
    }

    /* スマホアクセス */

    .access h3{
        font-size: 25px;
    }

    .access{
        padding: 80px 0 ;
        margin: 100px 0;
    }

    .access div{
        display: block;
        width: 80%;
        margin: 50px auto 0;
    }

    .access div p{
        padding-top: 50px;
        margin-left: 0px;
        font-size: 15px;
    }

    .access div iframe{
        width: 100%;
        height: 350px;
    }

    /* スマホフッター */

    footer ul{
        display: block;
        padding: 20px 0;
    }

    footer ul li{
        display: block;
        margin: auto;
        padding: 20px 0;
    }

    footer ul li a{
        font-size: 15px;
    }

      

}

</style>
