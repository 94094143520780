import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/room',
    name: 'room',
    component: () => import('../views/RoomView.vue')
  },
  {
    path: '/reserve',
    name: 'reserve',
    component: () => import('../views/ReserveView.vue')
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('../views/AccessView.vue')
  },
  {
    path: '/tour',
    name: 'tour',
    component: () => import('../views/TourView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/C',
    name: 'homeC',
    component: () => import('../views/HomeViewC.vue')
  },
  {
    path: '/roomC',
    name: 'roomC',
    component: () => import('../views/RoomViewC.vue')
  },
  {
    path: '/reserveC',
    name: 'reserveC',
    component: () => import('../views/ReserveViewC.vue')
  },
  {
    path: '/accessC',
    name: 'accessC',
    component: () => import('../views/AccessViewC.vue')
  },
  {
    path: '/tourC',
    name: 'tourC',
    component: () => import('../views/TourViewC.vue')
  },
  {
    path: '/contactC',
    name: 'contactC',
    component: () => import('../views/ContactViewC.vue')
  },
  {
    path: '/E',
    name: 'homeE',
    component: () => import('../views/HomeViewE.vue')
  },
  {
    path: '/roomE',
    name: 'roomE',
    component: () => import('../views/RoomViewE.vue')
  },
  {
    path: '/reserveE',
    name: 'reserveE',
    component: () => import('../views/ReserveViewE.vue')
  },
  {
    path: '/accessE',
    name: 'accessE',
    component: () => import('../views/AccessViewE.vue')
  },
  {
    path: '/tourE',
    name: 'tourE',
    component: () => import('../views/TourViewE.vue')
  },
  {
    path: '/contactE',
    name: 'contactE',
    component: () => import('../views/ContactViewE.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
